/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'brand.svg',
  logoalt: 'brand-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_brand.scss',
  name: 'Horeca Crowdfunding',
  phone: '010-2617070',
  email: 'info@horecacrowdfunding.nl',
  website: 'https://horecacrowdfunding.be/',
  glossary: true,
  langFileName: 'brand.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireLegalBanner: true,
  hideRegisterButton: false,
  logoUrl: null,
};
